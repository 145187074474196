<template>
  <div class="">
    <dv-border-box-12>
      <e-chart
        :options="options"
        id="box"
        height="350px"
        width="100%"
      ></e-chart>
    </dv-border-box-12>
  </div>
</template>

<script>
import { months, categories } from "../mockData";
import EChart from "../../../common/echart/index.vue";
export default {
  components: {
    EChart,
  },
  data() {
    return {
      options: {
        title: {
          text: "渔业分类损失趋势",
          left: "1%",
          top: "3%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: categories,
          orient: "horizontal",
          left: "35%",
          top: "5%",
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "9%",
          top: "25%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: months,
        },
        yAxis: {
          type: "value",
          name: "损失额（万元）",
        },
        series: [
          {
            name: "草鱼",
            type: "line",
            stack: "Total",
            data: [2500, 2800, 2000, 1500, 2600, 4200, 7800, 6800, 5800],
            smooth: false, // 禁用曲线效果
          },
          {
            name: "生鱼",
            type: "line",
            stack: "Total",
            data: [800, 700, 600, 500, 800, 600, 1800, 1800, 1500],
            smooth: false, // 禁用曲线效果
          },
          {
            name: "黄颡鱼",
            type: "line",
            stack: "Total",
            data: [1200, 1500, 1600, 1900, 2500, 2500, 2800, 3200, 2500],
            smooth: false, // 禁用曲线效果
          },
          {
            name: "班叉鮰",
            type: "line",
            stack: "Total",
            data: [800, 700, 600, 500, 800, 600, 1800, 1800, 1500],
            smooth: false, // 禁用曲线效果
          },
          {
            name: "加州鲈",
            type: "line",
            stack: "Total",
            data: [1200, 1500, 1600, 1900, 2500, 2500, 2800, 3200, 2500],
            smooth: false, // 禁用曲线效果
          },
        ],
      },
    };
  },
};
</script>
