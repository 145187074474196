<template>
  <div class="">
    <dv-border-box-12>
      <dv-scroll-board
        class="relative"
        :config="mockData"
        style="width: 100%; height: 350px"
        @click="handleCellClicked"
      />
      <!-- <div class="chartModel" v-if="chartModelSwitch">
        <e-chart :options="options"></e-chart>
      </div> -->
    </dv-border-box-12>
  </div>
</template>

<script>
import { mockMarketingData } from "../mockData";
// import EChart from "../../../common/echart/index.vue";

export default {
  // components: {
  //   EChart,
  // },
  data() {
    return {
      mockData: mockMarketingData,
      chartModelSwitch: false,
      options: {
        title: {
          text: "销售额",
          left: "left",
        },
        tooltip: {},
        legend: {
          orient: "vertical",
          left: "left",
          top: "middle",
          data: ["销售额"],
        },
        series: [
          {
            name: "销售额",
            type: "pie",
            radius: "78%",
            center: ["55%", "50%"],
            data: [
              {
                name: "销售额",
                value: 100,
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    handleCellClicked(config) {
      console.log("config", config);
      console.log("ceil", config.ceil);
      console.log("index", config.rowIndex);
      this.chartModelSwitch = true;
      this.options = {
        title: {
          text: config.ceil,
          left: "1%",
          top: "3%",
        },
        tooltip: {},
        legend: {
          orient: "vertical",
          left: "left",
          top: "middle",
          data: [config.ceil],
        },
        series: [
          {
            name: config.ceil,
            type: "pie",
            radius: "78%",
            center: ["55%", "50%"],
            data: [
              {
                name: config.ceil,
                value: config.rowIndex,
              },
            ],
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.chartModel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 370px;
}
</style>
