<template>
  <div class="">
    <dv-border-box-12>
      <e-chart
        :options="options"
        id="box"
        height="350px"
        width="100%"
      ></e-chart>
    </dv-border-box-12>
  </div>
</template>

<script>
import { months, monthlySalesDistributionSeries } from "../mockData";
import EChart from "../../../common/echart/index.vue";

export default {
  components: { EChart },
  data() {
    return {
      options: {
        title: {
          text: "月度销售额分布图",
          left: "1%",
          top: "3%",
        },
        legend: {
          data: ["草鱼", "生鱼", "黄颡鱼", "班叉鮰", "加州鲈"],
          top: "15%",
          right: "15%",
        },
        grid: {
          top: "22%",
          bottom: "15%",
          left: "12%",
          right: "8%",
        },
        tooltip: {},
        xAxis: {
          data: months,
        },
        yAxis: {},
        series: monthlySalesDistributionSeries,
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#box {
}
</style>
