<template>
  <div class="">
    <dv-border-box-12>
      <e-chart
        :options="options"
        id="box"
        height="350px"
        width="100%"
      ></e-chart>
    </dv-border-box-12>
  </div>
</template>
<script>
import { fishSalesPieChartData } from "../mockData";
import EChart from "../../../common/echart/index.vue";
export default {
  components: {
    EChart,
  },
  data() {
    return {
      options: {
        title: {
          text: "品类销售占比",
          left: "1%",
          top: "3%",
        },
        tooltip: {},
        legend: {
          orient: "vertical",
          left: "3%",
          top: "middle",
          data: fishSalesPieChartData.categories,
        },
        series: [
          {
            name: "销售比例",
            type: "pie",
            radius: "80%",
            center: ["55%", "50%"],
            roseType: "area",
            data: fishSalesPieChartData.categories.map((category, index) => {
              return {
                name: category,
                value: fishSalesPieChartData.sales[index],
              };
            }),
            labelLine: {
              show: true,
            },
          },
        ],
      },
    };
  },
};
</script>
