export const listData = [
  {
    title: "综合单位成本",
    amount: 1222,
    unit: " 元/斤",
  },
  {
    title: "总产值",
    amount: 1222,
    unit: " 万元",
  },
  {
    title: "亩产值",
    amount: 1222,
    unit: " 万元",
  },
  {
    title: "饲料用量",
    amount: 1222,
    unit: " 斤",
  },
  {
    title: "饵料指数",
    amount: 1222,
    unit: "",
  },
];

export const monthlySalesDistributionSeries = [
  {
    name: "草鱼",
    type: "bar",
    stack: "total",
    label: {
      show: true,
    },
    emphasis: {
      focus: "series",
    },
    data: [150, 80, 50, 30, 15, 15, 15, 20, 30],
  },
  {
    name: "生鱼",
    type: "bar",
    stack: "total",
    label: {
      show: true,
    },
    emphasis: {
      focus: "series",
    },
    data: [60, 80, 0, 0, 0, 30, 30, 30, 0],
  },
  {
    name: "黄颡鱼",
    type: "bar",
    stack: "total",
    label: {
      show: true,
    },
    emphasis: {
      focus: "series",
    },
    data: [0, 0, 30, 40, 0, 0, 0, 0, 50],
  },
  {
    name: "班叉鮰",
    type: "bar",
    stack: "total",
    label: {
      show: true,
    },
    emphasis: {
      focus: "series",
    },
    data: [0, 0, 20, 30, 30, 10, 0, 0, 22],
  },
  {
    name: "加州鲈",
    type: "bar",
    stack: "total",
    label: {
      show: true,
    },
    emphasis: {
      focus: "series",
    },
    data: [55, 0, 0, 0, 22, 28, 25, 0, 0],
  },
];

export const fishSalesPieChartData = {
  categories: ["草鱼", "生鱼", "黄颡鱼", "班叉鮰", "加州鲈"],
  sales: [600, 270, 180, 192, 240],
};

export const marketData = {
  header: ["地区", "种类", "规格", "价格", "趋势"],
  data: [
    [
      "北京",
      "鲈鱼",
      "1斤",
      30,
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "福建",
      "虹鳟鱼",
      "2两",
      50,
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "浙江",
      "鲫鱼",
      "1吨",
      10000,
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "广东",
      "草鱼",
      "1斤",
      25,
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "湖南",
      "鲤鱼",
      "1.5斤",
      45,
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "海南",
      "金枪鱼",
      "0.5吨",
      7000,
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "山东",
      "鳗鱼",
      "1斤",
      80,
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "辽宁",
      "带鱼",
      "2斤",
      60,
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "江苏",
      "螃蟹",
      "3两",
      90,
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "四川",
      "鲶鱼",
      "1.2斤",
      35,
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "云南",
      "甲鱼",
      "1斤",
      100,
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "河北",
      "鱼翅",
      "100克",
      500,
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "陕西",
      "黄鳝",
      "0.5斤",
      55,
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "广西",
      "龙虾",
      "3两",
      150,
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "内蒙古",
      "蚌肉",
      "1斤",
      70,
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
  ],
};

export const lossAnalysisPieData = {
  categories: ["草鱼", "生鱼", "黄颡鱼", "班叉鮰", "加州鲈"],
  loss: [36000, 9100, 19700, 9100, 19700],
};

// 损耗趋势图数据

export const months = [
  "一月",
  "二月",
  "三月",
  "四月",
  "五月",
  "六月",
  "七月",
  "八月",
  "九月",
];

export const categories = ["草鱼", "生鱼", "黄颡鱼", "班叉鮰", "加州鲈"];

export const data = {
  草鱼: [10, 13, 22, 18, 14, 17, 23, 30, 12, 16, 16, 12],
  生鱼: [18, 10, 21, 10, 19, 12, 15, 9, 12, 15, 18, 12],
  黄颡鱼: [20, 23, 29, 28, 25, 23, 20, 19, 20, 18, 15, 14],
  班叉鮰: [30, 32, 29, 28, 28, 27, 30, 34, 28, 24, 25, 30],
  加州鲈: [20, 32, 12, 23, 25, 32, 28, 25, 20, 25, 29, 21],
};

export const lossTrendChartData = {
  months: months,
  categories: ["草鱼", "生鱼", "黄颡鱼", "班叉鮰", "加州鲈"],
  data: [
    [
      "2023/9/6",
      "常州",
      "草鱼",
      "5~6",
      "6.50 <span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/6",
      "常州",
      "草鱼",
      "7~8",
      "7.30 <span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/6",
      "常州",
      "鲈鱼",
      "1.2-1.3",
      "16.50 <span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/6",
      "南京",
      "草鱼",
      "4~7",
      "6.50 <span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/6",
      "扬州",
      "草鱼",
      "5~6",
      "6.80 <span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/5",
      "常州",
      "草鱼",
      "5~6",
      "7.30 <span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/5",
      "常州",
      "草鱼",
      "7~8",
      "8.00 <span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/5",
      "常州",
      "鲈鱼",
      "1.2-1.3",
      "16.50 <span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/5",
      "南京",
      "草鱼",
      "4~7",
      "6.50 <span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/5",
      "扬州",
      "草鱼",
      "5~6",
      "7.20 <span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/4",
      "常州",
      "草鱼",
      "5~6",
      "6.00 <span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/4",
      "常州",
      "草鱼",
      "7~8",
      "7.30 <span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/4",
      "常州",
      "鲈鱼",
      "1.2-1.3",
      "17.00 <span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/4",
      "南京",
      "草鱼",
      "4~7",
      "6.50 <span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/4",
      "扬州",
      "草鱼",
      "5~6",
      "6.50 <span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
  ],
};

export const mockMarketingData = {
  header: ["日期", "地区", "种类", "规格", "价格", "趋势"],
  data: [
    [
      "2023/9/4",
      "常州",
      "草鱼",
      "5~6",
      "6.00",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/4",
      "常州",
      "草鱼",
      "7~8",
      "7.30",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/4",
      "常州",
      "鲈鱼",
      "1.2-1.3",
      "17.00",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/4",
      "南京",
      "草鱼",
      "4~7",
      "6.50",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/4",
      "扬州",
      "草鱼",
      "5~6",
      "6.50",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/3",
      "常州",
      "草鱼",
      "5~6",
      "7.00",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/3",
      "常州",
      "草鱼",
      "7~8",
      "7.80",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/3",
      "常州",
      "鲈鱼",
      "1.2-1.3",
      "17.00",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/3",
      "南京",
      "草鱼",
      "4~7",
      "6.50",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/3",
      "扬州",
      "草鱼",
      "5~6",
      "7.20",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/6",
      "常州",
      "草鱼",
      "5~6",
      "6.50",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/6",
      "常州",
      "草鱼",
      "7~8",
      "7.30",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/6",
      "常州",
      "鲈鱼",
      "1.2-1.3",
      "16.50",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/6",
      "南京",
      "草鱼",
      "4~7",
      "6.50",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/6",
      "扬州",
      "草鱼",
      "5~6",
      "6.80",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/5",
      "常州",
      "草鱼",
      "5~6",
      "7.30",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/5",
      "常州",
      "草鱼",
      "7~8",
      "8.00",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/5",
      "常州",
      "鲈鱼",
      "1.2-1.3",
      "16.50",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/5",
      "南京",
      "草鱼",
      "4~7",
      "6.50",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    [
      "2023/9/5",
      "扬州",
      "草鱼",
      "5~6",
      "7.20",
      "<span style='border-bottom:1px solid #0ad8d7;color:#0ad8d7;cursor:pointer;'>查看趋势</span>",
    ],
    // ... (continue with more data entries)
  ],
};
