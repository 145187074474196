<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div class="host-body" v-else>
        <big-header></big-header>
        <div class="host-body-box">
          <div class="top-row">
            <div class="top-row--item">
              <div class="top-row--item__img-wpr">
                <img src="../../assets/images/totalUnitCost.png" alt="" />
              </div>
              <div class="top-row--item__content">
                <div class="top-row--item__content__text">综合单位成本</div>
                <div class="top-row--item__content__amount glowing-text">
                  19 <span class="subtext">&nbsp;元/斤</span>
                </div>
              </div>
            </div>
            <div class="top-row--item">
              <div class="top-row--item__img-wpr">
                <img src="../../assets/images/gross.png" alt="" />
              </div>
              <div class="top-row--item__content">
                <div class="top-row--item__content__text">总产值</div>
                <div class="top-row--item__content__amount glowing-text">
                  997 <span class="subtext">&nbsp;万元</span>
                </div>
              </div>
            </div>
            <div class="top-row--item">
              <div class="top-row--item__img-wpr">
                <img src="../../assets/images/OutputValuePerAcre.png" alt="" />
              </div>
              <div class="top-row--item__content">
                <div class="top-row--item__content__text">亩产值</div>
                <div class="top-row--item__content__amount glowing-text">
                  1.25 <span class="subtext">&nbsp;万元</span>
                </div>
              </div>
            </div>
            <div class="top-row--item">
              <div class="top-row--item__img-wpr">
                <img src="../../assets/images/totalLoss.png" alt="" />
              </div>
              <div class="top-row--item__content">
                <div class="top-row--item__content__text">损耗总量</div>
                <div class="top-row--item__content__amount glowing-text">
                  9.36 <span class="subtext">&nbsp;万斤</span>
                </div>
              </div>
            </div>
            <div class="top-row--item">
              <div class="top-row--item__img-wpr">
                <img src="../../assets/images/coefficient.png" alt="" />
              </div>
              <div class="top-row--item__content">
                <div class="top-row--item__content__text">存塘量</div>
                <div class="top-row--item__content__amount glowing-text">
                  49.51 <span class="subtext">&nbsp;斤</span>
                </div>
              </div>
            </div>
          </div>
          <div class="content-box">
            <mid-first></mid-first>
            <mid-second></mid-second>
            <mid-third></mid-third>
          </div>
          <div class="bottom-box">
            <bottom-first></bottom-first>
            <bottom-second></bottom-second>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import drawMixin from "../../utils/drawMixin";
import BigHeader from "../../layout/header.vue";
import MidFirst from "./layout/MidFirst.vue";
import MidSecond from "./layout/MidSecond.vue";
import MidThird from "./layout/MidThird.vue";
import BottomFirst from "./layout/BottomFirst.vue";
import BottomSecond from "./layout/BottomSecond.vue";
export default {
  mixins: [drawMixin],
  components: {
    BigHeader,
    MidFirst,
    MidSecond,
    MidThird,
    BottomFirst,
    BottomSecond,
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.cancelLoading();
  },
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/index.scss";

.host-body-box {
  display: flex;
  flex-direction: column;

  //下方区域的布局
  .content-box {
    width: 1920px;
    display: grid;
    grid-template-columns: 519px 622px 622px;
    justify-content: space-between;
    padding: 0 40px;
    margin-bottom: 35px;
  }

  // 底部数据
  .bottom-box {
    display: grid;
    grid-template-columns: 519px 1284px;
    justify-content: space-between;
    grid-column-gap: 40px;
    width: 1920px;
    padding: 0 40px;
  }
}
.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 158px 55px 158px;
  padding-top: 55px;
  &--item {
    display: flex;
    &__img-wpr {
      width: 95px;
      height: 100%;
      margin-right: 36px;
      & img {
        width: 100%;
        object-fit: contain;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__text {
        font-weight: normal;
        font-size: 18.65px;
        color: #62a2cd;
      }
      &__amount {
        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url(../../assets/images/kuang.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: 18px;
        height: 44px;
        width: 100px;
        font-weight: bold;
        font-size: 25.26px;
        & .subtext {
          font-weight: normal;
          font-size: 16px;
          color: #24aeff;
        }
      }
    }
  }
}
</style>
